import React, { useContext } from "react"
import { Container, Row, Col } from "reactstrap"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import TitleBanner from "../components/titleBanner/TitleBanner"
import TitleBannerEnquiry from "../components/titleBanner/TitleBannerEnquiry"
import CardsList from "../components/common/cardsList/CardsList"
import Reviews from "../components/product-detail/itemReviews/Reviews"
import { GlobalStateContext } from "../context/GlobalContextProvider"

let CoursesAutodesk = ({ data }) => {
  const { allContentfulTraining } = data
  const autodeskCourses = allContentfulTraining.nodes
  return (
    <>
      <SEO
        title="Best Autodesk Revit & AutoCAD Training Courses | Ace Industrial Academy"
        description="Ace Industrial Academy is an Autodesk Authorized Test Center based in Singapore. We conduct courses for CAD & BIM softwares such as AutoCAD and Revit."
      />

      <TitleBannerEnquiry
        title="Autodesk BIM Courses"
        description="Start learning AutoCAD, Revit, BIM360 and more at an Autodesk Authorized Training Center"
        withOnlyDescription
        withoutFilter
      />

      <div className="container">
        <CardsList
          withoutFilter
          courses={autodeskCourses}
        />
        {/* <div className="reviews-cover">
          <Reviews />
        </div> */}
      </div>
    </>
  )
}

export const autodeskQuery = graphql`
  query allAutodeskQuery {
    allContentfulTraining(
      filter: { category: { eq: "autodesk" } }
    ) {
      nodes {
        id
        title
        courseReference
        trainingMode
        updatedAt
        skillsFramework
        requirements
        lessons
        duration
        product {
          id
          name
          netFee
          price
        }
        metaDescription
        category
        slug
        coverPhoto {
          gatsbyImageData
        }
        courseRuns
      }
    }
  }
`

export default CoursesAutodesk
